//import { redirect, RouteObject } from "react-router-dom";
import { RouteObject } from "react-router-dom";
//import { useAuthStore } from "@/stores/useAuthStore";

// Layouts
import { AuthLayout, DashboardLayout } from "../layouts";

// Pages
import {
//  Orders,
  OrdersTwo,
//  Customers,
  SignIn,
  ForgotPassword,
  NewPassword,
} from "./lazyWrappers";
import ProtectedRoutes from "./ProtectedRoutes";
import NotFound from "../NotFound";

// Helper function to check if user can access customers page
//const canAccessCustomers = (userType: number) => userType !== 3;

export const routesConfig: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedRoutes />,
    children: [
      {
        element: <DashboardLayout />,
        children: [
          { index: true, element: <Navigate to="/orders" replace /> },
          {
            path: "orders",
            element: <OrdersTwo />,
            // element: canAccessCustomers(3) ? <Orders /> : <OrdersTwo />,
            loader: () => ({
              meta: {
                title: "Siparişler",
                description: "Siparişlerini takip et",
              },
            }),
          },
          // {
          //   path: "customers",
          //   element: <Customers />,
          //   loader: () => {
          //     const { userData } = useAuthStore.getState();
          //     // Redirect to orders if user doesn't have access
          //     if (!canAccessCustomers(userData.userType)) {
          //       return redirect("/orders");
          //     }
          //     return {
          //       meta: {
          //         title: "Müşteriler",
          //         description: "Müşterilerini düzenle",
          //       },
          //     };
          //   },
          // },
        ],
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      { path: "sign-in", element: <SignIn /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "new-password", element: <NewPassword /> },
    ],
  },
  { path: "*", element: <NotFound /> },
];
