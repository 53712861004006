import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface UserData {
  fullname: string;
  userType: number;
}
interface AuthState {
  isSignedIn: boolean;
  userData: UserData;
  setIsSignedIn: (val: boolean) => void;
  setUserData: (val: UserData) => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isSignedIn: false,
      userData: {
        fullname: "",
        userType: 0,
      },
      setIsSignedIn: (val) => set({ isSignedIn: val }),
      setUserData: (val) => set({ userData: val }),
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
