import { lazy } from "react";

// Auth
export const SignIn = lazy(() => import("../modules/Auth/SignIn"));
export const NewPassword = lazy(() => import("../modules/Auth/NewPassword"));
export const ForgotPassword = lazy(
  () => import("../modules/Auth/ForgotPassword")
);
// export const SignUp = lazy(() => import("../modules/Auth/SignUp"));

// User
// export const Profile = lazy(() => import("../modules/User/Profile"));
// export const ChangePassword = lazy(
//   () => import("../modules/User/ChangePassword")
// );
// export const Settings = lazy(() => import("../modules/User/Settings"));

// Orders
export const Orders = lazy(() => import("../modules/Orders"));
export const OrdersTwo = lazy(() => import("../modules/OrdersTwo"));

// Customers
export const Customers = lazy(() => import("../modules/Customers"));
